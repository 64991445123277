.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

/* .App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Nav bar */

.nav-main {
  background-color: grey;
  font-family: monospace;
  color:whitesmoke;
  display:flex;
  font-size: larger;
  margin: 0;
  padding: 0;
}

.navlink {
  text-decoration: none;
  font-weight : bolder;
  text-decoration : none;
  font-size : larger;
  color : whitesmoke; 
}

.navlink:hover {
  color : black;
  text-shadow: 0 0 5px white;
}

.basic-navbar-nav {
  background-color: grey;
}

.vertical-scrollable {
  position: absolute;
  width: 50%;
  overflow-y: scroll;
}

.gameCardMain {
  font-family: monospace;
  color: grey;
  align-items: center;
  box-shadow: #282c34;
  border-radius: 1px;
}

.ImageBox {
  height: 1000px;
  width: 500px;
}

.TextBox {
  height: 1000px;
  width: 500px;
}

.nav-container {
  display: flex;
  margin-right: 2%;
}

.board {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 100px);
  gap: 5px;
}

.row {
  display: contents;
}

.cell {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 2em;
}

.btboard {
  display: grid;
  grid-template-columns: repeat(6, 30px);
  grid-template-rows: repeat(6, 30px);
  gap: 5px;
  padding:5%;
}

.btrow {
  display: contents;
}

.btcell {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 2em;
}